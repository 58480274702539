import React from "react"
import { HeaderBlock, CtaButton } from "shared-ui"

import Layout from "../features/Layout"
import Seo from "../features/Seo"

const NotFoundPage = () => {
  const notFoundData = {
    noSupporters: true,
    footer: {
      head: `Did you find a bug?`,
      sub: `Navigate to the contact form below to let us know!`,
      buttons: (
        <CtaButton square outLink={"https://workforce.promazo.com/contact/"}>
          Contact Us
        </CtaButton>
      ),
    },
  }
  return (
    <Layout pageData={notFoundData}>
      <Seo title="Sorry! We couldn't find this page" />
      <HeaderBlock
        pad="12rem 2rem 10rem"
        headerText="Oops! this doesn't exist..."
        subText={
          <p style={{ marginBottom: `1rem` }}>
            Click below to get back to the main content.
          </p>
        }
      >
        <CtaButton square>Go Back Home</CtaButton>
      </HeaderBlock>
    </Layout>
  )
}

export default NotFoundPage
